



























import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Ads from "@/components/shared/Ads.vue";

@Component({
    components: {
        PageTitleHeader,
        "fmi-ads": Ads,
        ResourcesContent: () => {
            const options = store.getters.getPageOptions("resources");
            const componentToUse = options?.components?.content;
            return import(`./resources-content/${componentToUse}`);
        }
    }
})
export default class ResourcesPageView extends Vue {
    get isMidsummer() {
        return this.$store.getters.layoutOptions.siteName === "midsummer";
    }
}
